import React from 'react';

const Mission = () => {
  return (
    <div className="missions">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-6 mb-5">
            <div>
              <div className="amt">
                <span>AMT</span>
              </div>
              <div className="camerr">
                <span>Cameroun SA</span>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6 mb-5">
            <div>
              <div className="wordss">
                <span>
                AMT CAMEROON SA is an experienced provider experienced
                 provider of integrated logistics, transport and maritime  
                 services in a modern supply chain management environment.
                </span>
              </div>
              <div className="mt-5 wordss">
                <span>
                  Our commitment to efficiency, reliability, and innovation
                  ensures that we consistently exceed expectations, enabling
                  businesses to thrive in today's dynamic marketplace. Whether
                  it's managing complex transportation networks, facilitating
                  seamless cargo movement, or optimizing warehousing and
                  distribution processes, AMT CAMEROUN SA stands ready to
                  streamline operations and drive success for our valued
                  partners.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mission;
